import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { BlogPost } from '../components/BlogPost'

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const { t } = useTranslation()
  const bgColor = '#1b1c22'

  var txt = ''
  if (typeof document !== "undefined") {
    var txtAux = document.createElement("textarea");
    txtAux.innerHTML = post.excerpt
    txt = txtAux.value
  }

  return (
    <div style={{ backgroundColor: bgColor }}>
      <Layout
        header={t("header", { returnObjects: true })}
        footer={t("footer", { returnObjects: true })}
        bgColor={bgColor}
        dark
      >
        <Seo title={post.title} description={txt} />
        <BlogPost
          data={{ previous, next, post }}
        />
      </Layout>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $language: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "DD MMMM YYYY", locale: "pt")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, JPG]
                    quality: 100
                )
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
        uri
        title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
        uri
        title
    }

    locales: allLocale(filter: {ns: {in: ["blog", "global"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`

import * as React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'
import parse from "html-react-parser"
import { Grid, Cell } from "styled-css-grid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { media } from './MediaQueries'

const BlogPost = ( { data }) => {
    const breakpoints = useBreakpoint()
    const image = {
        image: getImage(data.post.featuredImage?.node?.localFile),
        alt: data.post.featuredImage?.node?.altText || '',
    }

    return(
        <BlogPostStyled>
            <article className="blog-post">
                <Grid columns={breakpoints.l ? 1 : 6} gap={breakpoints.l ? '15vw' : '4vw'} className='post'>
                    <Cell width={breakpoints.l ? 1 : 3} middle>
                        {image?.image &&
                            <GatsbyImage image={image.image} alt={image.alt || ''}/>
                        }
                    </Cell>
                    <Cell width={breakpoints.l ? 1 : 3} middle>
                        <h2>{parse(data.post.title)}</h2>
                        <span className='date'>{data.post.date}</span>
                    </Cell>

                    <Cell width={breakpoints.l ? 1 : 6}>
                        {!!data.post.content && (
                            <section className='content'>{parse(data.post.content)}</section>
                        )}
                    </Cell>
                </Grid>
            </article>

            <nav className="blog-post-nav">
                <hr/>
                <ul>
                    <li>
                        {data.previous && (
                            <Link to={'/blog' + data.previous.uri} rel="prev">
                                ← {parse(data.previous.title).slice(0, 50) + '...'}
                            </Link>
                        )}
                    </li>

                    <li>
                        {data.next && (
                            <Link to={'/blog' + data.next.uri} rel="next">
                                {parse(data.next.title).slice(0, 50) + '...'} →
                            </Link>
                        )}
                    </li>
                </ul>
                <hr/>
            </nav>
        </BlogPostStyled>
    )
}

export { BlogPost }

const BlogPostStyled = styled.div`
    .blog-post{
        max-width:1920px;
        margin:0 auto;
        padding: clamp(30px, 5vw, 60px) 5%;        

        li{
            margin-bottom: 2vh;
        }

        .post{
            position:relative;

            .gatsby-image-wrapper{
                max-height:500px;
                width:100%;
                height:100%;
            }

            h2{
                font-family: 'Cabin', sans-serif;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: #eeefed;
                font-size:2rem;
                z-index:2;
                max-width:550px;
            
                /* ${media.xl`
                    font-size:3rem;
                `}

                ${media.m`
                    font-size:2rem;
                `}

                ${media.s`
                    font-size:1.5rem;
                `} */
            }

            .date{
                display: block;
                font-weight:bold;
                color: #eeefed;
                text-transform:uppercase;
                font-size:0.7rem;
                margin-top:0.5rem;
            }

            .excerpt{
                font-family: 'Cabin', sans-serif;
                letter-spacing: 1px;
                color: #eeefed;
                font-size: 1.2rem;
                line-height: 1.5;
                margin-top:4rem;
            }

            .content{
                color:#eeefed;
                font-weight:100;
                font-size:1.3rem;

                figure{
                    margin:0;

                    video{
                        width:100%;
                    }
                }

                ul{
                    padding:0;
                    display: flex;
                    gap: 2rem;
                    width: 100%;
                    list-style-type: none;
                    flex-wrap:wrap;

                    li{
                        width:100%;
                        flex:1 1 35%;

                        ${media.m`
                            flex:unset;
                        `}

                        span{
                            width:100% !important;
                        }
                    }
                }

                p{
                    margin:1.5rem 0;
                    line-height:2rem;
                }

                .gatsby-image-wrapper{
                    max-height:unset;
                }
            }
        }
    }

    .blog-post-nav{
        max-width:1920px;
        margin:0 auto;
        padding: clamp(15px,3vw,30px) 5%;

        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
            padding: 0;
            gap:2rem;

            ${media.xl`
                justify-content:center;
            `}

            li{
                text-align:center;
                
                a{
                    color: #eeefed;
                }
            }
        }
    }
`